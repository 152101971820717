"use client";

import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import { useTheme } from "@/contexts/themeProvider";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import http, { httpBase, httpRaw } from "@/lib/http";
import { handleResponseError } from "@/utils/errors";
import { currencyFormat } from "@/utils/helpers";
import CustomSelect from "../CustomWalletTransferRecipientSelect";
import { IWallet } from "@/types/user.type";
import Inputamount from "../inputamount/inputamount";
import { debounce } from "lodash";
import { message } from "antd";
import Inputselect from "../inputselect/inputselect";
// import { FaHouseUser } from "react-icons/fa";

interface externalAccountLookUpPayload {
  code: string;
  number: string;
}

interface internalAccountLookUpPayload {
  identifier: string;
}

interface getSupportedBanksInstitutionsPayload {
  country_code?: string;
}

function TransferWalletModal({
  children,
  data,
  walletDebit,
}: {
  children: React.ReactNode;
  data: IWallet;
  walletDebit: (wallet_id: string, remark: string, amount: string) => void;
}) {
  const { isOpen, onOpen, onClose: majorClose } = useDisclosure();
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [accountNumber, setAccountNumber] = useState("");
  const [identifier, setIdentifier] = useState<string>("");
  const [recipient_id, setReceipient] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [tab, setTab] = useState<"internal" | "external">("internal");
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [recipientsInternal, setRecipientsInternal] = useState<any[]>([]);
  const [recipientsExternal, setRecipientsExternal] = useState<any[]>([]);

  const [errors, setErrors] = useState({
    remark: "",
    amount: "",
    identifier: "",
    accountNumber: "",
    code: "",
  });

  async function fetchDatas() {
    try {
      const resp = await Promise.all([
        httpRaw(
          `https://api.lucrestack.co/wallet/transfer/internal/${data.currency.toLowerCase()}/recipient/details`
        ),
        httpRaw(
          `https://api.lucrestack.co/wallet/transfer/external/${data.currency.toLowerCase()}/recipient/details`
        ),
      ]);
      // console.log("resp", resp);
      setRecipientsInternal(resp[0].data);
      setRecipientsExternal(resp[1].data);
      setIsFetchingData(false);
    } catch (error) {
      // console.log(error);
      setIsFetchingData(false);
    }
  }

  async function walletInternalTransfer() {
    const newErrors = { ...errors };

    if (identifier?.trim()?.length === 0) {
      newErrors.identifier = "Email or Phone is required";
    } else {
      newErrors.identifier = "";
    }

    if (amount?.trim()?.length === 0) {
      newErrors.amount = "Amount is required";
    } else {
      newErrors.amount = "";
    }

    if (remark?.trim()?.length === 0) {
      newErrors.remark = "Remark is required";
    } else {
      newErrors.remark = "";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      setIsFetching(true);
      try {
        const resp = await httpRaw(
          `https://api.lucrestack.co/wallet/transfer/internal/${data.currency.toLowerCase()}/initiate/single-direct`,
          {
            wallet_id: data.wallet_id,
            identifier: identifier,
            remark,
            amount,
          }
        );
        // console.log("resp", resp.data);
        walletDebit(data.wallet_id, remark, amount);
        toast({
          title: "Success",
          description: resp.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        setIsFetching(false);
      } catch (error) {
        // console.log(error);
        setIsFetching(false);
        handleResponseError(error, toast);
      }
    }
  }

  async function walletExternalTransfer() {
    const newErrors = { ...errors };

    newErrors["accountNumber"] = validateAccountNumber(accountNumber);

    if (code?.trim()?.length === 0) {
      newErrors.code = "Bank is required";
    } else {
      newErrors.code = "";
    }

    if (amount?.trim()?.length === 0) {
      newErrors.amount = "Amount is required";
    } else {
      newErrors.amount = "";
    }

    if (remark?.trim()?.length === 0) {
      newErrors.remark = "Remark is required";
    } else {
      newErrors.remark = "";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      setIsFetching(true);
      try {
        const resp = await httpRaw(
          `https://api.lucrestack.co/wallet/transfer/external/${data.currency.toLowerCase()}/initiate/single-direct`,
          {
            wallet_id: data.wallet_id,
            // recipient_id_id: recipient_id,
            remark,
            amount,
            code,
            number: accountNumber as string,
          }
        );
        // console.log("resp", resp.data);
        walletDebit(data.wallet_id, remark, amount);
        toast({
          title: "Success",
          description: resp.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        setIsFetching(false);
      } catch (error) {
        // console.log(error);
        setIsFetching(false);
        handleResponseError(error, toast);
      }
    }
  }

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [code, setCode] = useState("");
  const [loadingSupportedBanks, setLoadingSupportedBanks] = useState(false);
  const [supportedBanks, setSupportedBanks] = useState([]);
  const [lookUpError, setLookUpError] = useState<null | string>(null);
  const [lookingUpAccount, setLookingUpAccount] = useState(false);
  const [lookupResult, setLookupResult] = useState<
    | {
        name: string;
        bank: string;
        number?: number;
        phone?: string;
      }[]
    | null
  >(null);

  const resetInputs = () => {
    setIdentifier("");
    setAmount("");
    setRemark("");
    setAccountNumber("");
    setCode("");

    setErrors({
      remark: "",
      amount: "",
      identifier: "",
      accountNumber: "",
      code: "",
    });
  };

  const onClose = () => {
    if (!isFetching) {
      majorClose();

      setLookUpError(null);
      setLookingUpAccount(false);
      setLookupResult(null);

      resetInputs();
    }
  };

  const externalAccountLookUp = (data: externalAccountLookUpPayload) => {
    return httpBase("/wallet/transfer/external/ngn/recipient/lookup", data);
  };

  const internalAccountLookUp = (data: internalAccountLookUpPayload) => {
    return httpBase("/wallet/transfer/internal/ngn/recipient/lookup", data);
  };

  const getSupportedBanksInstitutions = (
    data: getSupportedBanksInstitutionsPayload
  ) => {
    return httpBase(
      "/wallet/transfer/external/ngn/recipient/institutions",
      data
    );
  };

  const accountLookUp = async ({
    identifierValue,
    accountNumber: accountNumberValue,
    code: codeValue,
  }: {
    identifierValue?: string;
    accountNumber?: string;
    code?: string;
  }) => {
    try {
      setLookingUpAccount(true);
      setLookUpError(null);
      setLookupResult(null);

      let res;

      if (tab === "external") {
        res = await externalAccountLookUp({
          code: codeValue ?? code,
          number: accountNumberValue ?? (accountNumber as string),
        });
      } else {
        res = await internalAccountLookUp({
          identifier: identifierValue as string,
        });
      }

      const { status, message, data } = res;

      if (status === "success") {
        setLookupResult(tab === "external" ? data : [data]);
        setLookUpError(null);
      } else {
        setLookUpError(message);
        setLookupResult(null);
        // console.log(message);
      }

      setLookingUpAccount(false);
    } catch (error: any) {
      // console.log(error);
      setLookingUpAccount(false);
      setLookUpError(error?.message ?? "Something went wrong:  Account lookup");
    }
  };

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      if (query?.trim()?.length > 0) {
        accountLookUp({ identifierValue: query });
      }
    }, 500),
    [accountLookUp]
  );

  const checkNumberRegex = /^\d+$/;

  const validateAccountNumber = (value: string, limit = 10) => {
    let error = "";
    if (!checkNumberRegex.test(value)) {
      error = "Must contain only numbers";
    } else if (value.length < limit || value.length > limit) {
      error = `Must be a ${limit} digit number`;
    } else {
      error = "";
    }
    return error;
  };

  const updateField = (name: string, value: string) => {
    if (name === "identifier") {
      debouncedSearch(value);
      setIdentifier(value);
      setErrors((prevState) => ({
        ...prevState,
        identifier: "",
      }));
    }

    if (name === "accountNumber") {
      setAccountNumber(value);

      let newErrors = { ...errors };

      newErrors["accountNumber"] = validateAccountNumber(value);

      setErrors((prevState) => ({
        ...prevState,
        ...newErrors,
      }));

      if (
        Object.values({ accountNumber: newErrors.accountNumber }).every(
          (item) => item === ""
        )
      ) {
        accountLookUp({
          accountNumber: value,
        });
      } else {
        setLookupResult(null);
      }
    }

    if (name === "code") {
      setCode(value);
      setErrors((prevState) => ({
        ...prevState,
        code: "",
      }));

      // console.log("value", value);

      const checkAccountNumber = validateAccountNumber(accountNumber);
      if (checkAccountNumber?.trim()?.length === 0) {
        accountLookUp({
          code: value,
        });
      }
    }
  };

  const fetchAvailableBanks = async () => {
    setLoadingSupportedBanks(true);
    try {
      const res = await getSupportedBanksInstitutions({
        country_code: "ng",
      });

      const { status, message: resMessage, data } = res;

      if (status === "success") {
        setSupportedBanks(data);
      } else {
        message.error(`Supported banks: ${resMessage}`);
      }

      setLoadingSupportedBanks(false);
    } catch (error) {
      setLoadingSupportedBanks(false);
      message.error("Something went wrong:  Supported banks");
    }
  };

  useEffect(() => {
    fetchAvailableBanks();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div onClick={onOpen} className="inline-block cursor-pointer">
        {children}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        size={{ base: "sm", md: "lg" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            Wallet Transfer
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />

          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <div className="flex gap-5 mb-8">
              <button
                className={classNames("text-[13px]", {
                  "text-purple-600 underline font-bold": tab === "internal",
                  "": tab === "external",
                })}
                onClick={() => {
                  if (!isFetching) {
                    setTab("internal");
                    resetInputs();
                  }
                }}
              >
                Internal transfer
              </button>
              {data.type === "general" && (
                <button
                  className={classNames("text-[13px]", {
                    "text-purple-600 underline font-bold": tab === "external",
                    "": tab === "external",
                  })}
                  onClick={() => {
                    if (!isFetching) {
                      setTab("external");
                      resetInputs();
                    }
                  }}
                >
                  External transfer
                </button>
              )}
            </div>

            {tab === "internal" ? (
              <div className="mb-3">
                <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                  {currencyFormat(data.available_balance)}
                </div>

                {/* <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Currency</div>
                    <select value={currency} onChange={(e) => setCurrency(e.target.value)} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' disabled={isFetching}>
                      <option value="">Select</option>
                      <option value="ngn">NGN</option>
                      <option value="usd">USD</option>
                      <option value="gpb">GPB</option>
                      <option value="eur">EUR</option>
                    </select>
                  </div> */}

                {/* <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px] flex gap-2 items-center">
                    <div>Reciepient</div>
                    {isFetchingData && (
                      <ImSpinner8 className="w-3 h-3 animate-spin" />
                    )}
                  </div>
                  <CustomSelect
                    value={identifier}
                    options={recipientsInternal}
                    params={["name"]}
                    setValue={setIdentifier}
                  ></CustomSelect>
                </div> */}

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Email or Phone
                  </div>
                  <input
                    type="text"
                    onChange={(e) => {
                      updateField("identifier", e.target.value);
                    }}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    placeholder="Email/Phone"
                    disabled={isFetching}
                    value={identifier}
                  />
                  {errors?.identifier?.length > 0 && (
                    <span className="text-sm text-red-500">
                      {errors?.identifier}
                    </span>
                  )}
                </div>

                {identifier?.trim()?.length > 0 && (
                  <div className="mt-1 mb-2">
                    {lookingUpAccount ? (
                      <Skeleton h={"35px"} w={"100%"} />
                    ) : (
                      <>
                        {lookupResult && (
                          <div className="p-2 font-semibold text-green-500 bg-green-100 rounded-md font-poppins">
                            {lookupResult?.[0]?.name}
                          </div>
                        )}
                        {lookUpError && (
                          <div className="p-2 font-semibold text-red-400 bg-red-100 rounded-md font-poppins">
                            {lookUpError}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Amount
                  </div>
                  <Inputamount
                    name="amount"
                    updateField={(_, value) => {
                      setAmount(value);
                      setErrors((prevState) => ({
                        ...prevState,
                        amount: "",
                      }));
                    }}
                    placeHolder="Enter amount"
                    amountValue={amount}
                  />
                  {errors?.amount?.length > 0 && (
                    <span className="text-sm text-red-500">
                      {errors?.amount}
                    </span>
                  )}
                  {/* <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    placeholder="0.00"
                    disabled={isFetching}
                  /> */}
                </div>

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Remark
                  </div>
                  <input
                    type="text"
                    onChange={(e) => {
                      setRemark(e.target.value);
                      setErrors((prevState) => ({
                        ...prevState,
                        remark: "",
                      }));
                    }}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    disabled={isFetching}
                    placeholder="Remark"
                    value={remark}
                  />
                  {errors?.remark?.length > 0 && (
                    <span className="text-sm text-red-500">
                      {errors?.remark}
                    </span>
                  )}
                </div>

                <button
                  className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                  onClick={() => walletInternalTransfer()}
                  disabled={isFetching}
                >
                  {isFetching ? (
                    <ImSpinner8 className="w-5 h-5 animate-spin" />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            ) : (
              <div className="mb-3">
                <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                  {currencyFormat(data.available_balance)}
                </div>

                {/* <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Currency</div>
                    <select value={currency} onChange={(e) => setCurrency(e.target.value)} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' disabled={isFetching}>
                      <option value="">Select</option>
                      <option value="ngn">NGN</option>
                      <option value="usd">USD</option>
                      <option value="gpb">GPB</option>
                      <option value="eur">EUR</option>
                    </select>
                  </div> */}

                <div className="mb-5">
                  {/* <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px] flex gap-2 items-center">
                    <div>Reciepient</div>
                    {isFetchingData && (
                      <ImSpinner8 className="w-3 h-3 animate-spin" />
                    )}
                  </div>
                  <CustomSelect
                    value={recipient_id}
                    options={recipientsExternal}
                    params={["name", "bank"]}
                    setValue={setReceipient}
                  ></CustomSelect> */}
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Beneficiary Account Number
                  </div>
                  <input
                    type="text"
                    value={accountNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || /^[0-9]+$/.test(value)) {
                        updateField("accountNumber", value);
                      }
                    }}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    disabled={isFetching}
                    placeholder="Account Number"
                    maxLength={10}
                    max={10}
                  />

                  {code?.length > 0 &&
                    errors?.accountNumber.length === 0 &&
                    accountNumber?.trim()?.length > 0 && (
                      <div className="mt-1 mb-2">
                        {lookingUpAccount ? (
                          <Skeleton h={"35px"} w={"100%"} />
                        ) : (
                          <>
                            {lookupResult && (
                              <div className="p-2 font-semibold text-green-400 bg-green-100 rounded-md font-poppins">
                                {lookupResult?.[0]?.name}
                              </div>
                            )}
                            {lookUpError && (
                              <div className="p-2 font-semibold text-red-400 bg-red-100 rounded-md font-poppins">
                                {lookUpError}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}

                  {errors?.accountNumber?.length > 0 && (
                    <span className="text-sm text-red-500">
                      {errors?.accountNumber}
                    </span>
                  )}
                </div>

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Bank
                  </div>

                  <Inputselect
                    placeHolder="Choose Bank"
                    updateField={updateField}
                    name={"code"}
                    selectOptions={supportedBanks}
                    optionHasValue={false}
                    alternativeValue="code"
                    selectOptionLabel="name"
                    loading={loadingSupportedBanks}
                    showSearch={true}
                  />

                  {errors?.code?.length > 0 && (
                    <span className="text-sm text-red-500">{errors?.code}</span>
                  )}
                </div>

                {/* className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' */}

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Amount
                  </div>
                  <Inputamount
                    name="amount"
                    updateField={(_, value) => {
                      setAmount(value);
                      setErrors((prevState) => ({
                        ...prevState,
                        amount: "",
                      }));
                    }}
                    placeHolder="Enter amount"
                    amountValue={amount}
                  />
                  {errors?.amount?.length > 0 && (
                    <span className="text-sm text-red-500">
                      {errors?.amount}
                    </span>
                  )}
                  {/* <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    placeholder="0.00"
                    disabled={isFetching}
                  /> */}
                </div>

                <div className="mb-5">
                  <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                    Remark
                  </div>
                  <input
                    type="text"
                    onChange={(e) => {
                      setRemark(e.target.value);
                      setErrors((prevState) => ({
                        ...prevState,
                        remark: "",
                      }));
                    }}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    disabled={isFetching}
                    placeholder="Remark"
                    value={remark}
                  />
                  {errors?.remark?.length > 0 && (
                    <span className="text-sm text-red-500">
                      {errors?.remark}
                    </span>
                  )}
                </div>

                <button
                  className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                  onClick={() => walletExternalTransfer()}
                  disabled={isFetching}
                >
                  {isFetching ? (
                    <ImSpinner8 className="w-5 h-5 animate-spin" />
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            )}
          </ModalBody>

          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TransferWalletModal;
