"use client";

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useTheme } from "@/contexts/themeProvider";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import http from "@/lib/http";
import { handleResponseError } from "@/utils/errors";

function SendNotificationModal({ children }: { children: React.ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [body, setBody] = useState<string>("");
  const [header, setHeader] = useState<string>("");
  const [channel, setChannel] = useState<string>("");
  const [isFetching, setIsFetching] = useState(false);

  const [errors, setErrors] = useState({
    body: "",
    header: "",
    channel: "",
  });

  const validateInputs = () => {
    const newErrors = {
      body: body.trim() === "" ? "Body is required." : "",
      header: header.trim() === "" ? "Header is required." : "",
      channel: channel.trim() === "" ? "Channel is required." : "",
    };
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.values(newErrors).every((error) => error === "");
  };

  async function sendNoti() {
    if (!validateInputs()) {
      return;
    }

    setIsFetching(true);
    try {
      const resp = await http("notification/post", { header, body, channel });
      // console.log("resp", resp.data)
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // onClose();
      window.location.reload();
      // setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className="inline-block cursor-pointer">
        {children}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        size={{ base: "sm", md: "lg" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            Send notification
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />

          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <div className="mb-3">
              {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{currencyFormat(data.available_balance)}</div> */}
              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Channel
                </div>
                <select
                  value={channel}
                  onChange={(e) => setChannel(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                  required
                >
									<option value="" disabled>Select Channel</option>
                  <option value="email">Email</option>
                  <option value="sms">SMS</option>
                  <option value="inapp">In App</option>
                </select>

                {errors.channel && (
                  <div className="text-xs text-red-500">{errors.channel}</div>
                )}
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Header
                </div>
                <input
                  type="text"
                  value={header}
                  onChange={(e) => setHeader(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                  required
									placeholder="Header"
                />
                {errors.header && (
                  <div className="text-xs text-red-500">{errors.header}</div>
                )}
              </div>
              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Body
                </div>
                <textarea
                  rows={4}
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                  required
									placeholder="Body"
                />
                {errors.body && (
                  <div className="text-xs text-red-500">{errors.body}</div>
                )}
              </div>

              <button
                className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                onClick={() => sendNoti()}
                disabled={isFetching}
              >
                {isFetching ? (
                  <ImSpinner8 className="w-5 h-5 animate-spin" />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </ModalBody>

          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SendNotificationModal;
