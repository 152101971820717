"use client";

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useTheme } from "@/contexts/themeProvider";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import http from "@/lib/http";
import { handleResponseError } from "@/utils/errors";

function UpdateUserModal({
  children,
  data,
  updateUser,
}: {
  children: React.ReactNode;
  data: any;
  updateUser: (
    user_id: string,
    email: string,
    phone: string,
    status: string,
    accountType: string
  ) => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resolvedTheme } = useTheme();
  const toast = useToast();

	const [recoveryAuth, setRecoveryAuth] = useState('None')
  const [status, setStatus] = useState<string>(data.details.status);
  const [email, setEmail] = useState<string>(data.details.email);
  const [phone, setPhone] = useState<string>(data.details.phone);
  const [accountType, setAccountType] = useState<string>(
    data.details.account_type
  );
  const [isFetching, setIsFetching] = useState(false);
	

  async function teamUpdate() {
    setIsFetching(true);
    try {
      const resp = await http("user/update", {
        user_id: data.details.user_id,
        email,
        phone,
        status,
        account_type: accountType,
				recovery_auth: recoveryAuth
      });
      // console.log("resp", resp.data)
      updateUser(data.details.user_id, email, phone, status, accountType);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className="inline-block cursor-pointer">
        {children}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        size={{ base: "sm", md: "lg" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            Update user
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />

          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <div className="mb-3">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                {data.parameter}
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Email
                </div>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Phone
                </div>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Account Type
                </div>
                <select
                  value={accountType}
                  onChange={(e) => setAccountType(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                >
                  <option value="">Select</option>
                  {data.details.kyc.available_account_type &&
                    (data.details.kyc.available_account_type as any[]).map(
                      (t, k) => (
                        <option value={t} key={k}>
                          {t}
                        </option>
                      )
                    )}
                </select>
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Status
                </div>
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                >
                  <option value="">Select an option</option>
                  <option value="Approved">Approved</option>
                  <option value="Banned">Banned</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Recovery Auth
                </div>
                <select
                  value={recoveryAuth}
                  onChange={(e) => setRecoveryAuth(e.target.value)}
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                >
                  <option value="">Select an option</option>
                  <option value="None">None</option>
                  <option value="Cancel">Cancel</option>
                </select>
              </div>

              <button
                className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                onClick={() => teamUpdate()}
                disabled={isFetching}
              >
                {isFetching ? (
                  <ImSpinner8 className="w-5 h-5 animate-spin" />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </ModalBody>

          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpdateUserModal;
