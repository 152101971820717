import React, { useEffect, useState } from "react";
import Select from "react-select";
import { inputSelectInterface } from "./inputselect-interface";
import { background } from "@chakra-ui/react";

interface EnhancedInputSelectInterface extends inputSelectInterface {
  searchInNodes?: boolean;
}

const Inputselect = ({
  hasError,
  placeHolder,
  selectOptions,
  updateField,
  name,
  showSearch,
  optionHasValue,
  loading,
  selectOptionLabel,
  selectValue,
  alternativeValue,
  isMultiple,
  icon,
  disabled,
  searchInNodes = false,
}: EnhancedInputSelectInterface) => {
  const [currentOptions, setCurrentOptions] = useState<any[]>([]);

  useEffect(() => {
    let options: any[] = selectOptions?.map?.((item) => ({
      value: optionHasValue
        ? item
        : alternativeValue
        ? item[alternativeValue]
        : item.id,
      label: selectOptionLabel !== undefined ? item[selectOptionLabel] : item,
    })) || [];
		console.log(options)
    setCurrentOptions(options);
  }, [selectOptions]);

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      borderWidth: state.isFocused ? "1px" : "2px", // Ensure consistent border width
      borderColor: state.isFocused ? "#525252" : "#525252", // Neutral-600 or gray
      backgroundColor: state.isDisabled ? "#d1d5db" : "#f9fafb", // Gray-200
      outline: "none",
      boxShadow: state.isFocused ? "0 0 0 1px #525252" : "none",
      "&:hover": { borderColor: "#525252" },
      borderRadius: "0px",
      background: "#fff",
      height: "2.7rem",
      // Maintain hover state
    }),
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isSelected
        ? "#525252" // Neutral-600
        : state.isFocused
        ? "#e5e7eb" // Gray-300
        : "#ffffff", // White
      color: state.isSelected ? "#ffffff" : "#000000", // Text color
      padding: "0.5rem",
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: "0.75rem", // Text-xs
      color: "#6b7280", // Gray-500
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: "0.75rem", // Text-xs
      color: "#111827", // Gray-900
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: "#e5e7eb", // Gray-300
      borderRadius: "0.375rem", // Rounded-md
      padding: "0.25rem",
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      fontSize: "0.75rem", // Text-xs
      color: "#111827", // Gray-900
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      color: "#525252", // Neutral-600
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#525252", // Neutral-600
        color: "#ffffff", // White
      },
    }),
  };

  return (
    <div>
      <Select
        options={currentOptions}
        isLoading={loading}
        placeholder={placeHolder}
        name={name}
        isDisabled={disabled}
        isSearchable={showSearch}
        isMulti={isMultiple}
        onChange={({ value }) => {
					console.log(value);
          if (updateField !== undefined) {
            updateField(name, value);
          }
        }}
        styles={customStyles}
      />
    </div>
  );
};

Inputselect.defaultProps = {
  loading: false,
  showSearch: false,
  optionHasValue: false,
  disabled: false,
  selectOptions: [],
  isMultiple: false,
  searchInNodes: false,
};

export default Inputselect;
