"use client";

import React, { useEffect, useState } from "react";
import http from "@/lib/http";
import { BsSendCheck } from "react-icons/bs";
import { Skeleton } from "@chakra-ui/react";
import SendNotificationModal from "@/components/modals/SendNotificationModal";

function NotificationClient() {
  const [isFetching, setIsFetching] = useState(true);
  const [notification, setNotification] = useState<{
    header: string;
    body: string;
    channel?: string;
  } | null>(null);

  async function fetchNotification() {
    try {
      const resp = await http("notification/details");
      // console.log("resp", resp.data)
      setNotification(resp.data);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
    <div className="w-full px-5 py-8 sm:px-10">
      <div className="mb-8">
        <div className="mb-1 text-xl font-bold">Notification</div>
        <div className="text-xs text-neutral-500">
          Send notifications to users
        </div>
      </div>

      <hr className="mb-10 border-slate-300 dark:border-neutral-700" />

      <SendNotificationModal>
        <div className="flex items-center gap-3 mb-10 text-purple-500">
          <BsSendCheck />
          <button>Send new notification</button>
        </div>
      </SendNotificationModal>

      <div className="flex justify-center mb-10">
        <div className="w-[300px] md:w-[500px] p-5 bg-slate-50 dark:bg-neutral-800 border-2 border-slate-200 dark:border-neutral-600 shadow-md rounded-lg">
          {isFetching ? (
            <>
              <Skeleton h={"25px"} mb={"16px"} />
              <Skeleton h={"200px"} mb={"10px"} />
            </>
          ) : !notification ? (
            <>
              <div className="mb-4 text-sm font-bold">
                No notification at the moment !
              </div>
            </>
          ) : (
            <>
              <div className="mb-4 text-sm font-bold">
                Channel: {notification?.channel}
              </div>
              <div className="mb-4 text-sm font-bold underline underline-offset-2">
                Header: {notification.header}{" "}
                {notification?.channel === "inapp"
                  ? "In App"
                  : notification?.channel}
              </div>
              <div className="text-sm">Body: {notification.body}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationClient;
